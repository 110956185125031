import {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth/auth.context';
import { loadGoals, loadPoiList } from '../../api/common';
import { convertReportValuesToNumbers, convertReportValuesToString, initFormValues } from '../../utils';
import { ResultDataInterface, PoiInterface } from '../../interfaces';
import { UserRole, Path } from '../../enums';
import { ReportValuesKeys } from '../../constants';
import { Dropdown } from '../../theme';

import {
  Container,
  Inputs,
  StyledInput,
  Button,
  Checkmark,
} from './addGoals.styled';
import { updateGoals } from './addGoals.api';

export const AddGoals = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [pois, setPois] = useState<PoiInterface[]>([]);
  const [pos, setPos] = useState('');
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [currentGoals, setCurrentGoals] = useState<ResultDataInterface>();
  const [values, setValues] = useState(initFormValues);
  const [updateFinished, setUpdateFinished] = useState(false);
  const canEditGoals = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;
  const firstOfMonth = dayjs(new Date()).startOf('month').format('D.MM');
  const lastOfMonth = dayjs(new Date()).endOf('month').format('D.MM');
  const goalsAreEmpty = !currentGoals || JSON.stringify(currentGoals) === '{}';

  useEffect(() => {
    if (canEditGoals) {
      loadPoiList(setPois);
      loadGoals(dayjs(new Date()), setCurrentGoals);
    } else {
      navigate(Path.Home);
    }
  }, []);

  useEffect(() => {
    if (!goalsAreEmpty) {
      const posGoals = currentGoals[pos.toLowerCase()];
      setValues(convertReportValuesToString(posGoals));
    }
  }, [pos, currentGoals]);

  const handlePosChange = ({ target }: SelectChangeEvent<unknown>) => {
    setSendButtonDisabled(true);
    setPos(target.value as string);
    setValues(initFormValues);
    setUpdateFinished(false);
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSendButtonDisabled(false);
    setUpdateFinished(false);

    setValues({
      ...values,
      [target.name]: Number(target.value) < 0 ? 0 : target.value,
    });
  };

  const onFinishSaveGoals = () => {
    loadGoals(dayjs(new Date()), setCurrentGoals);
    setUpdateFinished(true);
  };

  const saveGoals = () => {
    setSendButtonDisabled(true);
    updateGoals({
      ...convertReportValuesToNumbers(values),
      poiId: pos,
    }, goalsAreEmpty, onFinishSaveGoals);
  };

  return (
    <Container>
      <Typography variant="h4">{t('goals.addGoals')}</Typography>
      {t('goals.period')}
      {' '}
      {firstOfMonth}
      {' - '}
      {lastOfMonth}
      <Inputs>
        <Dropdown
          value={pos}
          onChange={handlePosChange}
          displayEmpty
          disabled={!pois.length}
        >
          <MenuItem value="">
            <em>{t('addReport.poi')}</em>
          </MenuItem>
          {pois.map((poi) => (
            <MenuItem key={poi.id} value={poi.id}>
              <b>{poi.name.toUpperCase()}</b>
            </MenuItem>
          ))}
        </Dropdown>
        {!!pos && ReportValuesKeys.map((key) => (
          <StyledInput
            key={key}
            label={t(`goals.${key}`)}
            variant="outlined"
            placeholder={t('goals.inputPlaceholder')}
            type="number"
            value={values[key]}
            name={key}
            onChange={handleInputChange}
            onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        ))}
      </Inputs>
      <Button
        variant="contained"
        color="success"
        disabled={!pos || Object.values(values).some((value) => !value) || sendButtonDisabled}
        onClick={saveGoals}
      >
        {t('goals.save')}
        {updateFinished && <Checkmark>&#10004;</Checkmark>}
      </Button>
    </Container>
  );
};
