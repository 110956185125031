export enum ReportValueKey {
  TEL = 'tel',
  ODK = 'odk',
  VAS = 'vas',
  // KRED = 'kred', TODO: keep this line for a while, maybe it will be needed soon
  US = 'us',
  POZ_TOTAL = 'pozTotal',
  ZAT_TOTAL = 'zatTotal',
  ISP = 'isp',
  CALL = 'call',
}
